import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';

export const Title = styled.div`
  text-align: center;
  font-size: ${isMobile ? '26px' : '38px'};
  line-height: 1.3em;
  font-weight: ${isMobile ? 500 : 400};
`;

export const Button = styled.button`
  background: #9b87dd;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  cursor: hand;
  transition: all .4s linear;
  outline: 0;
  -webkit-appearance: none;

  &:hover {
    background: #8169d4;
  }

  ${props => props.isRaw && css`
    background: none;
    color: #9b87dd;
    font-weight: 500;

    &:hover {
      background: none;
      color: #8169d4;
    }
  `}

  ${props => props.disabled && css`
    background-color: #eee;
    color: #bbb;
    pointer-events: none;
  `}
`;

export const Anchor = styled(Link)`
  background: #9b87dd;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  cursor: hand;
  transition: all .4s linear;
  text-decoration: none;
  outline: 0;

  &:hover {
    background: #8169d4;
  }

  ${props => props.isRaw && css`
    background: none;
    color: #9b87dd;
    font-weight: 500;

    &:hover {
      background: none;
      color: #8169d4;
    }
  `}
`;

export const HomepageItem = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: center;
  color: #333;

  img {
    border-radius: 5px;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    transition: all .3s linear;
    display: block;
    margin: 0 auto 15px;
  }

  &:hover {
    img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }
`;
