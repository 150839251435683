import styled from 'styled-components';
import { Link } from 'gatsby';

export const PortfolioItem = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  position: relative;

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  img {
    border-radius: 5px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all .3s linear;
    display: block;
    margin: 0 auto 15px;
  }

  &:hover {
    img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
`;
