import styled from 'styled-components';

export const NavigationArrow = styled.div`
  position: fixed;
  top: 60%;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;

  a {
    color: #ccc;
    text-decoration: none;

    &:hover {
      color: #222;
    }
  }

  &.left {
    left: 30px;
  }

  &.right {
    right: 30px;
  }
`;
