import React from 'react';

import CategoryPage from '../../components/CategoryPage';

const NewBorn = () => (
  <CategoryPage
    title="Smake the Cake"
    totalItems={2}
    s3Path={`portfolio/smash-the-cake/`}
    internalPath={`/smash-the-cake/`}
    nextPath={`/bebes`}
    nextLabel="Bebê"
    prevPath={`/casamento`}
    prevLabel="Casamento"
  />
)

export default NewBorn;
