/* eslint-disable import/first */
typeof window !== 'undefined' && require('intersection-observer');

import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { LazyImage } from 'react-lazy-images';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { Link } from 'gatsby';

import Layout from '../Layout';
import Breaker from '../Breaker';
import SEO from '../seo';
import { Title } from '../../styles/base';
import { PortfolioItem } from '../../styles/portfolios';
import { SHOWCASE_PATH, PORTFOLIO_PATH } from '../../constants/Routes';
import { KEYWORDS } from '../../constants/Keywords';
import Loader from '../../images/Loader.svg';
import { getS3BaseUrl } from '../../utils/s3';

import { NavigationArrow } from './style';

const CategoryPage = ({
  title,
  totalItems,
  s3Path,
  internalPath,
  prevPath,
  prevLabel,
  nextPath,
  nextLabel
}) => (
  <Layout>
    <SEO title={title} keywords={[...KEYWORDS]} />

    <Title>{title}</Title>

    <Breaker />

    {/* <NavigationArrow className="left">
      <Link to={`${PORTFOLIO_PATH}${prevPath}`}>
        <ArrowLeft size={24} />
        <br />
        {prevLabel}
      </Link>
      </NavigationArrow>

      <NavigationArrow className="right">
      <Link to={`${PORTFOLIO_PATH}${nextPath}`}>
        <ArrowRight size={24} />
        <br />
        {nextLabel}
      </Link>
    </NavigationArrow> */}

    <Grid>
      <Row>
        {Array.apply(null, { length: totalItems }).map(Number.call, Number).reverse().map(k => {
          var photoName = k + 1;
          var S3_PATH = `${getS3BaseUrl()}${s3Path}${photoName}.jpg`;

          return (
            <Col md={4} key={k}>
              <PortfolioItem to={`${SHOWCASE_PATH}${internalPath}${photoName}`}>
                <LazyImage
                  src={S3_PATH}
                  alt={`${title}${photoName}`}
                  placeholder={({ imageProps, ref }) => (
                    <div style={{ padding: '80px 0' }} ref={ref}>
                      <Loader />
                    </div>
                  )}
                  actual={({ imageProps }) => <img {...imageProps} alt={imageProps.alt} />}
                />
              </PortfolioItem>
            </Col>
          )
        })}
      </Row>
    </Grid>
  </Layout>
);

export default CategoryPage;
